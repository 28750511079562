var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "knowledge-base-page" } }, [
    _c("div", { staticClass: "knowledge-base-jumbotron" }, [
      _c(
        "div",
        {
          staticClass:
            "knowledge-base-jumbotron-content lg:p-32 md:p-24 sm:p-16 p-8 rounded-lg mb-base",
        },
        [
          _c("h1", { staticClass: "mb-1 text-white" }, [
            _vm._v("Dedicated Source Used on Website"),
          ]),
          _c("p", { staticClass: "text-white" }, [
            _vm._v(
              "Bonbon sesame snaps lemon drops marshmallow ice cream carrot cake croissant wafer."
            ),
          ]),
          _c("vs-input", {
            staticClass: "w-full mt-6",
            attrs: {
              "icon-no-border": "",
              placeholder: "Search Topic or Keyword",
              "icon-pack": "feather",
              icon: "icon-search",
              size: "large",
            },
            model: {
              value: _vm.knowledgeBaseSearchQuery,
              callback: function ($$v) {
                _vm.knowledgeBaseSearchQuery = $$v
              },
              expression: "knowledgeBaseSearchQuery",
            },
          }),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "vx-row" },
      _vm._l(_vm.filteredKB, function (item) {
        return _c(
          "div",
          {
            key: item.id,
            staticClass: "vx-col w-full md:w-1/3 sm:w-1/2 mb-base",
            on: {
              click: function ($event) {
                _vm.$router.push(item.url).catch(() => {})
              },
            },
          },
          [
            _c("vx-card", { staticClass: "text-center cursor-pointer" }, [
              _c("img", {
                staticClass: "mx-auto mb-4",
                attrs: { src: item.graphic, alt: "graphic", width: "180" },
              }),
              _c("h4", { staticClass: "mb-2" }, [
                _vm._v(_vm._s(item.title.toUpperCase())),
              ]),
              _c("small", [_vm._v(_vm._s(item.description))]),
            ]),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }